<template>
    <div>
      <div class="d-print-none d-block">
    <div class="row">
      <div class="col">
        <div
          class="container-fluid col-md-12"
          style="
            background: rgb(248, 249, 250);
            border-radius: 10px;
            box-shadow: 5px 5px 5px 5px #999999;
          "
          id="contenedor"
        ><!--
        <div class="d-flex justify-content-center">
                              <div class="row">
                                  <div class="col">
                                  <br>
                                  <img src="https://seguimiento.cmimbral.cl/assets/logo_tienda.png"  width="200" class="img-fluid" alt="Responsive image"><br>
                                  </div>
                              </div>
                              
                          </div>--><br>
              <h3 class="text-center"><b>Buscar Pedidos</b></h3><br>
           
        <div class="d-flex justify-content-center">
          <div class="row">
            <div class="col">
  <label>Buscar por</label>
                      <select class="form-select" aria-label="Default select example" id="busqueda" @change="cambio()" style="border-color: rgb(237, 126, 37);">
                       <option value="1">Ver todos</option>
                     <!--  <option value="2">Rango de fechas</option>--> 
                        <option value="Flota Mimbral">Flota Mimbral</option>
                        <option value="Retiro en Sucursal Casa Matriz">Retiro en tienda</option>
                        <option value="BlueExpress - Prioritario">Blue</option>
                        </select><br><br>
            </div>
          </div>
          
        </div>
           <div class="d-flex justify-content-center">
                              <div id="bFecha" style="display:none">
                              <div class="row">
                              <div class="col-sm-5">
                                  <label ><strong>Fecha desde</strong></label><br>
                                  <input type="date" class="form-control" id="fecha1f" style=" border: solid 1px; border-radius: 5px;border-color:rgb(237,126,37) "/>
                                  
                              </div>
                              <div class="col-sm-5">
                                  <label ><strong>Fecha hasta</strong></label><br>
                                  <input type="date" class="form-control" id="fecha2f" style=" border: solid 1px; border-radius: 5px; border-color:rgb(237,126,37)"/>
                              </div>
                                  
                                  <div class="col-sm"><label><strong>Buscar</strong></label><br>
                                          <button type="submit"  id="btnBuscar" class="btn btn-primary" @click="buscarFecha()"  style="background-color:rgb(0,56,143); border-color:rgb(237,126,37)"><i class="fas fa-search"></i></button>
                                  </div>
                          </div><br><br>
                              
                          </div>	
          </div>
  
  
          <table class="table table-striped table-responsive" style="display:block" id="tabla1">
             <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;">
              <tr>
                <th>Número</th>
                <th>RUT</th>
                <th>Cliente</th>
                <th>Fecha</th>
                <th>Teléfono</th>
                <th>Envío</th>
                <th>Dirección</th>
                <th>Total</th>
                <th>Documento</th>
                <th>Observación</th>
                <th>Estado</th>
                <th>Actualizar</th>
                
              </tr>
            </thead>
            <tbody style="font-size: 12px;">
              <tr v-for="x in Pedidos" :key="x.numero">
                <td>{{ x.numero }}</td>
                <td>{{ x.rut }}</td>
                <td>{{ x.cliente }}</td>
                <td>{{ x.fechaVenta }}</td>
                <td>{{ x.telefono }}</td>
                <td>{{ x.envio }}</td>
                <td>{{ x.direccion }}</td>
                <td>{{ x.total }}</td>
                <td>{{ x.documento }}</td>
                <td>{{ x.observacion}}
                    <button
                    type="button" class="btn btn-info btn-sm" @click="imprimirEtiqueta(x.numero,x.cliente,x.rut,x.telefono,x.envio,x.direccion,x.fechaVenta)">
                    <h6>Imprimir etiqueta</h6> 
                  </button></td>
                <td  v-if="x.estado === 0">
                  <h6><b>Ingresado</b></h6>
                </td>
                <td  v-else-if="x.estado === 1">
                  <h6><b>En pickeo</b></h6>
                </td>
                <td  v-else-if="x.estado === 2">
                  <h6><b>Listo para retiro</b></h6>
                </td>
                <td  v-else-if="x.estado === 3">
                  <h6><b>En dirección a destino</b></h6>
                </td>
                <!--<td  v-else-if="x.estado === 4">
                  <h6><b>En dirección a destino</b></h6>
                </td>-->
                <td  v-else-if="x.estado === 5">
                  <h6><b>Entregado</b></h6>
                </td>
                <td  v-if="x.envio === 'Retiro en Sucursal Casa Matriz' && x.estado === 0">
                  <button
                    type="button" class="btn btn-primary btn-sm" @click="pickeo(x.numero,x.cliente,x.rut,x.telefono,x.envio,x.direccion)">
                    <h6>Pickeo</h6> 
                  </button>
                </td>
                <td  v-else-if="x.envio === 'Retiro en Sucursal Casa Matriz' && x.estado === 1">
                  <button
                    type="button" class="btn btn-warning btn-sm" @click="listoRetiro(x.numero,x.cliente,x.telefono)">
                    <h6>Listo para retiro</h6> 
                  </button>
                </td>
                <td  v-else-if="x.envio === 'Retiro en Sucursal Casa Matriz' && x.estado === 2">
                  <button
                    type="button" class="btn btn-success btn-sm" @click="registrarEntrega(x.numero,x.cliente,x.telefono)">
                     <h6>Registrar Entrega</h6>
                  </button>
                </td>
                <td  v-else-if="x.envio === 'Flota Mimbral' && x.estado === 0">
                  <button
                    type="button" class="btn btn-primary btn-sm" @click="pickeo(x.numero,x.cliente,x.rut,x.telefono,x.envio,x.direccion)">
                     <h6>Pickeo</h6>  
                  </button>
                </td>
                <td  v-else-if="x.envio === 'Flota Mimbral' && x.estado === 1">
                  <button
                    type="button" class="btn btn-info btn-sm" @click="actualizarFlota(x.numero,x.cliente,x.telefono)">
                      <h6>En camino</h6>
                  </button>
                </td>
                <td  v-else-if="x.envio === 'Flota Mimbral' && x.estado === 3">
                  <button
                    type="button" class="btn btn-success btn-sm" @click="registrarEntrega(x.numero,x.cliente,x.telefono)">
                    <h6>Registrar Entrega</h6>
                  </button>
                </td>
                <td  v-else-if="x.envio === 'BlueExpress - Prioritario' && x.estado === 0">
                  <button
                    type="button" class="btn btn-primary btn-sm" @click="pickeo(x.numero,x.cliente,x.rut,x.telefono,x.envio,x.direccion)">
                   <h6>Pickeo</h6> 
                  </button>
                </td>
                <td  v-else-if="x.envio === 'BlueExpress - Prioritario' && x.estado === 1">
                
                  <button
                    type="button" class="btn btn-success btn-sm" @click="blue(x.numero)">
                      <h6>Entregado a Blue</h6>
                  </button>
                </td>
                
               <!-- <td>{{ guia.nombreProveedor }}</td>
                <td>{{ guia.oc }}</td>
                <td>{{ guia.nbultos }}</td>
                <td>{{ guia.npallet }}</td>
                <td>{{ guia.fecha}}</td>
                <td>{{ guia.observacion }}</td>
                <td v-if="guia.imagen!=null && guia.imagen!=''">
                  <router-link :to="{name: 'edit', params: { id: guia.imagen }}" class="btn btn-success btn-sm" style="
                      background-color: rgb(0, 56, 143);
                      color: white;
                      border-color: rgb(237, 126, 37);
                    "><i class="far fa-image fa-2x"></i>
                  </router-link>
                </td>
                <td v-else>
                  <h6 style="color:#ED7E25"><b>NO</b> </h6>
                </td>
                <td v-if="guia.prioridad=='NO'">-->
                  <!--<router-link :to="{name: 'edit', params: { id: cheque.key }}" class="btn btn-success btn-sm" style="
                      background-color: rgb(0, 56, 143);
                      color: white;
                      border-color: rgb(237, 126, 37);
                    "
                  ><i class="far fa-edit fa-2x"></i>
                              </router-link>-->
                  <!--<b-button v-b-modal.modal-1 
                  
                    type="button"
                    
                    class="btn btn-success btn-sm"
                    style="
                      background-color: rgb(0, 56, 143);
                      color: white;
                      border-color: rgb(237, 126, 37);
                    "
                    @click="cargarDatos(cheque.key,cheque.rutFacturacion,cheque.documento,cheque.rut,cheque.nombreTitular,cheque.razonSocial,cheque.monto,cheque.observacion)"
                  >
                    <i class="far fa-edit fa-2x"></i>
                  </b-button>
                  <b-button 
                    type="button"
                    
                    class="btn btn-success btn-sm"
                    style="
                      background-color: rgb(0, 56, 143);
                      color: white;
                      border-color: rgb(237, 126, 37);
                    "
                    @click="editar(guia.key)"
                  >
                    Marcar prioridad
                  </b-button>
                </td>
                 <td v-else>
                     <h6 style="color:green"><b>SÍ</b> </h6>
                  </td>-->
              </tr>
            </tbody>
          </table>
          <br>
  
          
          <img id="pdf" width="1%" height="1px"/>
        </div>
      </div>
      <div>
  
  
        <b-modal id="modal-entrega" hide-footer hide-header  size="lg">
      
      <div class="d-block text-center">
        <h3><b>Entregar Productos</b></h3>
        <div class="row">
                  <div class="col-sm">
                      <h6>Items</h6>
                              <table class="table table-striped table-responsive" style="display:block" id="tabla1">
                      <thead style="background-color:rgb(0,58,140);color:white;font-size: 12px;" >
                          <tr>
                              <th>SKU</th>
                              <th>NOMBRE</th>
                              <th>ÁREA</th>
                              <th>TOTAL</th>
                              <th>PENDIENTES</th>
                              <th>ENTREGADOS</th>
                          
                          </tr>
                      </thead>
                      <tbody style="font-size: 12px;">
                          <tr v-for="x in Items" :key="x.key">
                          <td>{{ x.sku }}</td>
                          <td>{{ x.nombre }}</td>
                          <td v-if="x.tipo=='VO'">Venta Online</td>
                          <td v-else-if="x.tipo=='PT'"><h6 style="font-size: 13px;color:red"><b>Patio</b></h6></td>
                          <td>{{ x.cantidad }}</td>
                          <td>{{ x.dif }}</td>
                          <td v-if="x.dif==0">
                              <h6 style="font-size: 12px;">Listo</h6>
                          </td>
                          <td v-else>
                            <input v-model="x.nuevaEntrega" type="number" min="0" :max="x.dif">
                          </td>
                  </tr>
                      </tbody>
                      </table>
                  </div>
              </div>
  
                  
                  <div class="d-flex justify-content-center">
                      <div class="form-group">
                          <button class="btn btn-primary btn-block" @click="registrarEntrega2()" style="background-color: rgb(0, 56, 143); color: white; border-color: rgb(237, 126, 37);">Entregar</button><br><br>
                      </div>
                  </div>
  
      </div>
  
    </b-modal>   
    <b-modal id="modal-1" hide-footer hide-header >
      
      <div class="d-block text-center">
        <h3><b>Editar Capacitación</b></h3><br>
        
                  <input type="text" id="key" v-model="key" class="form-control" placeholder="Ingrese RUT facturación" style="display:none"  >
                           
                  <div class="row">
                  
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label>Proveedor</label>
                          <input type="text" id="rutF" v-model="proveedor" class="form-control" placeholder="Ingrese proveedor" style="border-color:rgb(237,126,37)"   required>
                           </div>
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label>Relator</label>
                          <input type="text" id="nDoc" v-model="relator" class="form-control" placeholder="Ingrese n° documento" style="border-color:rgb(237,126,37)"  required>
                          </div>
                      </div>
                      
                      
                  </div><br>
                  
                  <div class="row">
                       <div class="col-sm-6">
                          <div class="form-group">
                          <label>Fecha</label>
                         <div class="input-group mb-3">
                           <input type="date" id="fecha" v-model="fecha" @change="verFecha()" class="form-control" style="border-color:rgb(237,126,37)"   required>
                          
                          </div>
                          </div>
                          
                      </div>
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label>Hora</label>
                          <input type="time" id="hora" v-model="hora" class="form-control" style="border-color:rgb(237,126,37)"  required>
                          </div>
                      </div>
                      
                      
                  </div><br>
                  <div class="row">
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label>Lugar</label>
                          <input type="text" id="razonS" v-model="lugar" class="form-control" placeholder="Ingrese nombre" style="border-color:rgb(237,126,37)"  required>
                          </div>
                      </div>
                      <div class="col-sm-6">
                          <label>Participantes</label>
                            <input type="text" id="participantes" v-model="participantes" class="form-control" placeholder="Ingrese nombre" style="border-color:rgb(237,126,37)"  required>
                      </div>
                  </div><br>
                  <div class="row">
                      <div class="col-sm-6">
                          <div class="form-group">
                          <label>Persona a cargo</label>
                          <input type="text" id="razonS" v-model="persona" class="form-control" placeholder="Ingrese persona" style="border-color:rgb(237,126,37)"  required>
                          </div>
                      </div>
                  </div><br>
                  <div class="row">
                      <div class="form-group">
                          <label for="observacion">Observacion</label>
                          <textarea class="form-control" id="observacion" v-model="observacion" rows="3"  style="border-color:rgb(237,126,37)"></textarea><br>
                      </div>
                  </div>
                 <!-- <div class="row" >
                      <div class="col-sm-6">
                          <input style="border-color:rgb(237,126,37)" @change="clickImagen($event)" type="file" accept="image/*" >
                      </div>
  
                  </div>--><br>
  
                  
                  <div class="d-flex justify-content-center">
                      <div class="form-group">
                          <button class="btn btn-primary btn-block" @click="editar()" style="background-color: rgb(0, 56, 143); color: white; border-color: rgb(237, 126, 37);">Editar</button><br><br>
                      </div>
                  </div>
  
      </div>
  
    </b-modal>
  </div>
    </div>
   </div>
  
  
  <div id="impresionEtiqueta" class="d-print-block d-none">
     
     
    <strong>Datos Pedido</strong>
  
         <div class="row">
             <div class="col-sm">
                <div v-for="x in datosEtiqueta" :key="x.key">
        <p>
            <strong>Número:</strong> {{ x.numero }}<br>
            <strong>Fecha:</strong> {{ x.fechaVenta }}<br>
            <strong>Cliente:</strong> {{ x.cliente }}<br>
            <strong>Teléfono:</strong> {{ x.telefono }}<br>
            <strong>Envío:</strong> {{ x.envio }}<br>
            <strong>Dirección:</strong> {{ x.direccion }}
        </p>
        </div>
                    
             </div>
         </div>
     
    </div>
  </div>
  </template>
  <style>
    h6{font-size: 12px;}
  </style>
  <script>
  import { db } from "../firebaseDb";
  import { storage } from "../firebaseDb";
  import Swal from "sweetalert2";
  import Vue from 'vue'
      import { BootstrapVue } from 'bootstrap-vue'
      Vue.use(BootstrapVue)
        var sd = require('silly-datetime');
  const ref = storage.ref();
  
  export default {
    data() {
      return {
        datosEtiqueta:[],
        Pedidos: [],
        vendedores:[],
        editado:[],
        chequeEditado:[],
        observacion:'',
        key:'',
        rutFacturacion:'',
        documento:'',
        rut:'',
        nombreTitular:'',
        razonSocial:'',
        monto:'',
        usuario:'',
        capacitacionEditada:[],
        proveedor:'',
        relator:'',
        fecha:'',
        hora:'',
        lugar:'',
        participantes:'',
        persona:'',
  
        semanaFecha:'',
        bultosTotales:'',
        palletsTotales:0,
        guiaPrioridad:[],
        Items:[],
        ItemsPatio:[],
        ItemsVo:[],
        info:[]
      };
    },
    created() {
      document.getElementById("nav").style.display="block";
      this.usuario=localStorage.usuario;
      this.validarUsuario();
      //this.cargarVendedores();
      
      this.cargarPedidos()
      
    },
    methods: {
      xitem(item) {
          // Aquí puedes realizar la lógica para enviar los datos al servidor o hacer otras operaciones
          console.log(`SKU: ${item.sku}, Cantidad Entregada: ${item.cantidad_entregada}`);
          // Puedes realizar una llamada AJAX aquí para enviar los datos al servidor y actualizar la base de datos
      },
  
      pickeo(numero,cliente,rut,telefono,envio,direccion){
          this.info=[]
                      this.info.pedido=numero
                      this.info.cliente=cliente
                      this.info.rut=rut
                      this.info.telefono=telefono
                      this.info.envio=envio
                      this.info.direccion=direccion
                      this.Items=[]
                      this.Seleccionados=[]
                      this.NC=[]
                  fetch('https://back.cmimbral.cl/pedido/'+numero)
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          //this.cargarDatos(data)
                          this.Items=data.items
                          this.pickeo2(numero) 
                          // Haz lo que necesites con los datos
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
  
                 
              },
  
      pickeoXX(numero){
              this.Items=[]
              this.ItemsVo=[]
              this.ItemsPatio=[]
              let y=this
                  fetch('https://api.cmimbral.cl/buscarItems/'+numero)
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          this.info=data[0];
                          data.forEach(function(dato){
                            if(dato.tipo=="VO"){
                              y.ItemsVo.push(dato)
                            }else{
                              y.ItemsPatio.push(dato)
                            }
                          })
                          this.ItemsImpresion=data
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
  
             this.pickeo2(numero) 
  
  
  
      },
      pickeo2(numero){
        console.log(numero)
        let y=this
        var x = {
                          //sku,nombre,cantidad,precio,pedido,tipo
                          numero: numero,
                          estado: 1,
                      };
  
                  fetch('https://api.cmimbral.cl/actualizarPedido', {
                          method: 'post',
                          headers: {
                                 'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                          },
                          //mode:'no-cors',
                          body: JSON.stringify(x) 
                  
                  })
                  .then(response => response.json())
                  .then(data => {
                      
                      setTimeout(() => {
                        console.log(data)
                        y.cargarPedidos()   
                        window.print()
                      }, 1500)
                  })
                  .catch(error => {
                      console.error('Error:', error);
                  });
      },
  
      listoRetiro(numero,nombre,telefono){
                 
                      var x = {
                          //sku,nombre,cantidad,precio,pedido,tipo
                          numero: numero,
                          estado:2,
                      };
  
                  fetch('https://api.cmimbral.cl/actualizarPedido', {
                          method: 'post',
                          headers: {
                                 'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                          },
                          //mode:'no-cors',
                          body: JSON.stringify(x) 
                  
                  })
                  .then(response => response.json())
                  .then(data => {
                      console.log(data)
                      const numeroTelefono = telefono; // Reemplaza con el número de teléfono deseado
                      const mensaje = 'Hola '+nombre+' tu pedido '+numero+' se encuentra listo para retiro. Acércate a nuestra sucursal ubicada en Chorrillos 2137.'; // Reemplaza con tu mensaje predefinido
  
                      const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
                      window.open(urlWhatsApp, '_blank');
                      this.cargarPedidos()
                  })
                  .catch(error => {
                      console.error('Error:', error);
                  });
  
      },
  
  
      actualizarFlota(numero,nombre,telefono){
                 
                 var x = {
                     //sku,nombre,cantidad,precio,pedido,tipo
                     numero: numero,
                     estado:3,
                 };
  
             fetch('https://api.cmimbral.cl/actualizarPedido', {
                     method: 'post',
                     headers: {
                            'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                     },
                     //mode:'no-cors',
                     body: JSON.stringify(x) 
             
             })
             .then(response => response.json())
             .then(data => {
                 console.log(data)
                 const numeroTelefono = telefono; // Reemplaza con el número de teléfono deseado
                 const mensaje = 'Hola '+nombre+' tu pedido '+numero+' va en dirección a destino. Llegará hoy entre las 08:00 hrs y las 20:00 hrs.'; // Reemplaza con tu mensaje predefinido
  
                 const urlWhatsApp = `https://wa.me/${numeroTelefono}?text=${encodeURIComponent(mensaje)}`;
                 window.open(urlWhatsApp, '_blank');
                 this.cargarPedidos()
             })
             .catch(error => {
                 console.error('Error:', error);
             });
  
  },
  
  
      registrarEntrega2(numero){
        console.log(numero)
        console.log(this.Items)
              /*  var x = {
                  numero: numero,
                  estado:5,
                };
  
             fetch('https://api.cmimbral.cl/actualizarPedido', {
                     method: 'post',
                     headers: {
                            'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                     },
                     //mode:'no-cors',
                     body: JSON.stringify(x) 
             
             })
             .then(response => response.json())
             .then(data => {
                 console.log(data)
                 
                 this.cargarPedidos()
             })
             .catch(error => {
                 console.error('Error:', error);
             });
  
             */
            this.actualizarItems()
  
  },
  
  
  actualizarItems(){
                  //console.log(this.Items)
                  
                  var data=this.Items
                  data.forEach(function(dato){
                    if(dato.nuevaEntrega!="0" && dato.dif!="0"){
                      console.log(dato)
                      var x = {
                          //sku,nombre,cantidad,precio,pedido,tipo
                          id:dato.id,
                          entregados:parseInt(dato.nuevaEntrega)+parseInt(dato.entregados)
                          
                      };
  
                  fetch('https://api.cmimbral.cl/entregarItems', {
                          method: 'post',
                          headers: {
                                 'Content-Type': 'application/json' // Establece el tipo de contenido como JSON
                          },
                          //mode:'no-cors',
                          body: JSON.stringify(x) 
                  
                  })
                  .then(response => response.json())
                  .then(data => {
                      console.log(data)
                  })
                  .catch(error => {
                      console.error('Error:', error);
                  });
  
                    }
                      
                  })
                  this.$bvModal.hide("modal-entrega")
                  Swal.fire({
                    title: "Listo",
                    text: "Entrega Registrada",
                    icon: "success",
                  });
                  //1- Registrar PEDIDO
                  //2- REGISTRAR DETALLE DE LOS ITEMS.
              },
  
  
  
  
      buscarPedido2(){
                  var pedido=document.getElementById("pedido").value
                  if(pedido!=""){
                      this.Items=[]
                      this.Seleccionados=[]
                      this.NC=[]
                  fetch('https://back.cmimbral.cl/pedido/'+pedido)
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          this.cargarDatos(data)
                          // Haz lo que necesites con los datos
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
  
                  }else{
                      alert("ingrese pedido")
                  }
              },
  
        cargarPedidosTipo(tipo){
          
          fetch('https://api.cmimbral.cl/pedidosTipo/'+tipo)
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          this.Pedidos=data
                          //this.cargarDatos(data)
                          // Haz lo que necesites con los datos
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
        },
        cargarPedidos(){
          this.info=[]
                      this.Items=[]
                      this.Seleccionados=[]
                      this.NC=[]
          this.Pedidos=[]
                  fetch('https://api.cmimbral.cl/pedidos')
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          this.Pedidos=data
                          //this.cargarDatos(data)
                          // Haz lo que necesites con los datos
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
  
              },
  
        registrarEntrega(numero){
          this.Items=[]
                  fetch('https://api.cmimbral.cl/buscarItems/'+numero)
                      .then((response) => {
                          if (!response.ok) {
                          throw new Error('La solicitud no tuvo éxito.');
                          }
                          return response.json(); // Analiza la respuesta como JSON
                      })
                      .then((data) => {
                          console.log(data);
                          this.Items=data
                          this.$bvModal.show("modal-entrega")
                          //this.cargarDatos(data)
                          // Haz lo que necesites con los datos
                      })
                      .catch((error) => {
                          console.error('Error:', error);
                      });
  
              },
  
      verFecha(){
                  var fecha=document.getElementById("fecha").value;
                  console.log(fecha)
                  var ndia=new Date(fecha).getDay() 
                  var dia=new Date(fecha)/*.getDay()     */
                  //console.log(ndia)
                  var currentdate = dia
                  var oneJan = new Date(currentdate.getFullYear(),0,1);
                  //console.log(oneJan)
                  var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
                  //console.log(numberOfDays)
                  var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
                  if(ndia==0 || ndia==1 || ndia==2){
                      var final= parseInt(result)+parseInt(1)
                      console.log("semana:"+final)
                      this.semanaFecha=final;
                      this.contarSemana(final)
                  }else if(ndia==6){
                      var x= parseInt(result)-parseInt(1)
                      console.log("semana:"+x)   
                      this.semanaFecha=x;
                      this.contarSemana(x)
                  }else{
                      console.log("semana:"+result)  
                      this.semanaFecha=result;
                      this.contarSemana(result)
                  }
                  //console.log(result)
                  //this.contarSemana(result)
              },
      cargarVendedores(){
                //  var vendedor = document.getElementById("vendedores")
                  db.collection('usuarios').where("tipo", "==", 1).onSnapshot((snapshotChange) => {
                  this.vendedores = [];
                  snapshotChange.forEach((doc) => {
                      var option = document.createElement("option")
                      option.value = doc.data().rut,
                      option.innerHTML = doc.data().nombre,
                      document.getElementById("vendedor").appendChild(option)
                      /*this.vendedores.push({
                          key: doc.id,
                          nombre: doc.data().nombre,
                          rut: doc.data().rut,
                          
                      })*/
                  });
              })
              },
      eliminar(id,usuario) {
        var user=localStorage.usuario;
        console.log(usuario,user)
        if(user==usuario){
          if (window.confirm("Realmente deseas eliminar el registro?")) {
          db.collection("capacitaciones")
            .doc(id)
            .delete()
            .then(() => {
              Swal.fire({
              title: "Listo",
              text: "Registro eliminado",
              icon: "success",
            });
            })
            .catch((error) => {
              console.error(error);
            });
        }
        }else{
          Swal.fire({
              title: "Error",
              text: "Sólo el usuario que registró el evento puede eliminarlo",
              icon: "error",
            });
        }
        
      },
      verArchivo(imagen) {
        console.log(imagen);
        document.getElementById("pdf").src = "";
        console.log(imagen);
        const refImg = ref
          .child("archivos/" + imagen)
          .getDownloadURL()
          .then(function (url) {
            console.log(refImg);
  
            var img = document.getElementById("pdf");
            img.src = url;
          })
          .catch(function (error) {
            console.log(error);
            Swal.fire({
              title: "Error",
              text: "No hay imagen asociada",
              icon: "error",
            });
          });
      },
      cambio(){
        this.Pedidos=[]
       var busqueda=document.getElementById("busqueda").value;
        console.log(busqueda)
        if(busqueda=="1"){
            this.cargarPedidos();
        }else{
            var tipo=document.getElementById("busqueda").value
            this.cargarPedidosTipo(tipo)
        }
  
      },
  
      buscarFecha(){
         // this.bultosTotales=0
        //  this.palletsTotales=0
        console.log("a")
          var fecha1= document.getElementById("fecha1f").value;
          var fecha2= document.getElementById("fecha2f").value;
         
                  var fecha12=fecha1.split("-");
                  var año=fecha12[0]
                  var mes=fecha12[1]
                  var dia=fecha12[2]
                  var fechaF1=año+""+mes+""+dia;
  
                  var fecha22=fecha2.split("-");
                  var año2=fecha22[0]
                  var mes2=fecha22[1]
                  var dia2=fecha22[2]
                  var fechaF2=año2+""+mes2+""+dia2;
           db.collection('capacitaciones').where("frcapacitacion", ">=", fechaF1).where("frcapacitacion", "<=", fechaF2).orderBy('frcapacitacion', 'asc').orderBy('hora', 'asc').onSnapshot((snapshotChange) => {
                  this.Capacitaciones2 = [];
                  if(snapshotChange.empty){
                    Swal.fire({
                        title: "Error",
                        text: "No se encontraron registros",
                        icon: "error",
                      });
                  }else{
                    this.largo=(snapshotChange.docs.length)
                      snapshotChange.forEach((doc) => {
                      var fecha1=doc.data().fecha.split("-");
                      this.Capacitaciones2.push({
                          key: doc.id,
                          nombreProveedor: doc.data().nombreProveedor,
                          persona: doc.data().persona,
                          relator: doc.data().relator,
                          participantes: doc.data().participantes,
                          lugar: doc.data().lugar,
                          observacion: doc.data().observacion, 
                          hora: doc.data().hora,
                          fecha: doc.data().fecha,
                          diaF:fecha1[2],
                          mesF:fecha1[1],
                          añoF:fecha1[0],
                          imagen: doc.data().imagen,
                          tipo: doc.data().tipo,
                          usuarioRegistro: doc.data().usuarioRegistro,
                      })
                      //this.bultosTotales=parseInt(this.bultosTotales) +parseInt(doc.data().nbultos)
                      //this.palletsTotales=parseInt(this.palletsTotales) +parseInt(doc.data().npallet)
                  });
                  }
                 
            })
      },
  
  
    contarSemana(result){
              var docRef = db.collection("capacitaciones").where("semanaFecha", "==", result);
              docRef.get().then(doc => {
                  console.log(doc.docs.length)
                  var largo=doc.docs.length
                  if(largo>=2){
                          Swal.fire({
                          title: "Aviso",
                          text: "Ya existen dos capacitaciones agendadas para esta semana",
                          icon: "info",
                          });
                  }
              }).catch(function(error) {
              console.log("Error getting document:", error);
                      });
  /*
               db.collection('capacitaciones').where("semanaFecha", "==", result).onSnapshot((snapshotChange) => {
                  if(snapshotChange.empty){
                   
                      console.log("no")
                      document.getElementById("btnRegistrar").style.display="block"
                  }else{
                      var largo=(snapshotChange.docs.length)
                      if(largo>=3){
                          Swal.fire({
                          title: "Error",
                          text: "Ya existen dos capacitaciones agendadas para esta semana, por favor seleccione otra.",
                          icon: "error",
                          });
                          document.getElementById("btnRegistrar").style.display="none"
                      }else{
                          document.getElementById("btnRegistrar").style.display="block"
                      }
                  }
                 
            })*/
          },
  
  
  
      buscarDocumento(){
          
         // var documento= document.getElementById("numero").value;
           db.collection('capacitaciones').where("fr", "==", '20211029').onSnapshot((snapshotChange) => {
                  this.Capacitaciones = [];
                  if(snapshotChange.empty){
                     Swal.fire({
                        title: "Error",
                        text: "No se encontraron capacitaciones",
                        icon: "error",
                      });
                  }else{
                      snapshotChange.forEach((doc) => {
                      this.Capacitaciones.push({
                          key: doc.id,
                          nguia: doc.data().nguia,
                          nombreProveedor: doc.data().nombreProveedor,
                          oc: doc.data().oc,
                          nbultos: doc.data().nbultos,
                          npallet: doc.data().npallet,
                          observacion: doc.data().observacion, 
                          imagen: doc.data().imagen,
                          fecha: doc.data().fecha+" "+doc.data().hora,
                          prioridad: doc.data().prioridad,
                          
                      })
                      this.bultosTotales=parseInt(this.bultosTotales) +parseInt(doc.data().nbultos)
                     this.palletsTotales=parseInt(this.palletsTotales) +parseInt(doc.data().npallet)
                  });
                  }
                  //this.calcularTotales()
                 
            })
      },
      obtenerFecha(){
              this.fecha=sd.format(new Date(), 'DD-MM-YYYY');
              this.dia=(sd.format(new Date(), 'DD'))
              this.mes=(sd.format(new Date(), 'MM'))
              this.año=(sd.format(new Date(), 'YYYY'))
              this.hora=sd.format(new Date(), 'HH:mm');
          },
      calcularTotales(){
          var data=this.Guias;
          data.forEach(function(dato){
              console.log(dato.nbultos)
              //this.bultosTotales=dato;
              //this.calcularTotales2(dato.nbultos)
              /*this.bultosTotales=parseInt( this.bultosTotales)+parseInt(dato.nbultos) 
              console.log(this.bultosTotales)*/
          })
      },
      calcularTotales2(bultos){
          this.bultosTotales=parseInt( this.bultosTotales)+parseInt(bultos) 
          console.log(this.bultosTotales)
          
      },
  
     
  
  /*
      async cargarEventos(){
        var fecha=(this.año+this.mes+this.dia)
        const citiesRef = db.collection('capacitaciones').where("frcapacitacion", ">=", fecha);
        const snapshot = await citiesRef.get();
        snapshot.forEach(doc => {
         // console.log(doc.id, '=>', doc.data());
          this.Capacitaciones.push({
                          key: doc.id,
                          nombreProveedor: doc.data().nombreProveedor,
                          persona: doc.data().persona,
                          relator: doc.data().relator,
                          participantes: doc.data().participantes,
                          lugar: doc.data().lugar,
                          observacion: doc.data().observacion, 
                          hora: doc.data().hora,
                          fecha: doc.data().fecha,
                          imagen: doc.data().imagen,
                          tipo: doc.data().tipo,
                      })
        });
        /*
        const cityRef = db.collection('capacitaciones');
        const doc = await cityRef.get();
        if (!doc.exists) {
          console.log('No such document!');
        } else {
          console.log('Document data:', doc.data());
        }*/
       /*
         
          var docRef = db.collection("capacitaciones")//.where("frcapacitacion", ">=", fecha);
              docRef.get().then(docRef => {
                  console.log(docRef)
                  
              }).catch(function(error) {
              console.log("Error getting document:", error);
                      });*/
  /*
           db.collection('capacitaciones').where("frcapacitacion", ">=", fecha).onSnapshot((snapshot) => {
                  this.Capacitaciones = [];
                  if(snapshot.empty){
                     console.log("no")
                  }else{
                      snapshot.forEach((doc) => {
                       this.Capacitaciones.push({
                          key: doc.id,
                          nombreProveedor: doc.data().nombreProveedor,
                          persona: doc.data().persona,
                          relator: doc.data().relator,
                          participantes: doc.data().participantes,
                          lugar: doc.data().lugar,
                          observacion: doc.data().observacion, 
                          hora: doc.data().hora,
                          fecha: doc.data().fecha,
                          imagen: doc.data().imagen,
                          tipo: doc.data().tipo,
                      })
                      
                  });
                  }
                 
            })*/
     // },
     cargarEventos(){
        var fecha=(this.año+this.mes+this.dia)
       db.collection('capacitaciones').where("frcapacitacion", ">=", fecha).onSnapshot((snapshot) => {
                  this.Capacitaciones = [];
                  if(snapshot.empty){
                     console.log("no")
                  }else{
                      snapshot.forEach((doc) => {
                       this.Capacitaciones.push({
                          key: doc.id,
                          nombreProveedor: doc.data().nombreProveedor,
                          persona: doc.data().persona,
                          relator: doc.data().relator,
                          participantes: doc.data().participantes,
                          lugar: doc.data().lugar,
                          observacion: doc.data().observacion, 
                          hora: doc.data().hora,
                          fecha: doc.data().fecha,
                          imagen: doc.data().imagen,
                          tipo: doc.data().tipo,
                          usuarioRegistro: doc.data().usuarioRegistro,
                      })
                      
                  });
                  }
                 
            })
     },
      buscarRut(){
         var rut= document.getElementById("rut").value;
         var fecha1= document.getElementById("fecha1RUT").value;
         var fecha2= document.getElementById("fecha2RUT").value;
         
                  var fecha12=fecha1.split("-");
                  var año=fecha12[0]
                  var mes=fecha12[1]
                  var dia=fecha12[2]
                  var fechaF1=dia+"-"+mes+"-"+año;
  
                  var fecha22=fecha2.split("-");
                  var año2=fecha22[0]
                  var mes2=fecha22[1]
                  var dia2=fecha22[2]
                  var fechaF2=dia2+"-"+mes2+"-"+año2;
           db.collection('cheques').where("rutFacturacion", "==", rut).where("fecha", ">=", fechaF1).where("fecha", "<=", fechaF2).orderBy('fecha', 'desc').orderBy('hora', 'desc').onSnapshot((snapshotChange) => {
                  this.Cheques = [];
                  if(snapshotChange.empty){
                    Swal.fire({
                        title: "Error",
                        text: "No se encontraron cheques",
                        icon: "error",
                      });
                  }else{
                      this.largo=(snapshotChange.docs.length)
                      snapshotChange.forEach((doc) => {
                      this.Cheques.push({
                         key: doc.id,
                          documento: doc.data().documento,
                          rut: doc.data().rut,
                          nombreTitular: doc.data().nombreTitular,
                          rutFacturacion: doc.data().rutFacturacion,
                          razonSocial: doc.data().razonSocial,
                          observacion: doc.data().observacion, 
                          monto: doc.data().monto,
                          imagen: doc.data().imagen,
                          fecha: doc.data().fecha+" "+doc.data().hora,
                      })
                  });
                  }
                 
            })
      },
  
  
      buscarRazon(){
         var razon= document.getElementById("razon").value.toUpperCase() ;
         var fecha1= document.getElementById("fecha1Razon").value;
         var fecha2= document.getElementById("fecha2Razon").value;
         
                  var fecha12=fecha1.split("-");
                  var año=fecha12[0]
                  var mes=fecha12[1]
                  var dia=fecha12[2]
                  var fechaF1=dia+"-"+mes+"-"+año;
  
                  var fecha22=fecha2.split("-");
                  var año2=fecha22[0]
                  var mes2=fecha22[1]
                  var dia2=fecha22[2]
                  var fechaF2=dia2+"-"+mes2+"-"+año2;
           db.collection('cheques').where("razonSocial", "==", razon).where("fecha", ">=", fechaF1).where("fecha", "<=", fechaF2).orderBy('fecha', 'desc').orderBy('hora', 'desc').onSnapshot((snapshotChange) => {
                  this.Cheques = [];
                  if(snapshotChange.empty){
                     Swal.fire({
                        title: "Error",
                        text: "No se encontraron cheques",
                        icon: "error",
                      });
                  }else{
                      snapshotChange.forEach((doc) => {
                      this.Cheques.push({
                         key: doc.id,
                          documento: doc.data().documento,
                          rut: doc.data().rut,
                          nombreTitular: doc.data().nombreTitular,
                          rutFacturacion: doc.data().rutFacturacion,
                          razonSocial: doc.data().razonSocial,
                          observacion: doc.data().observacion, 
                          monto: doc.data().monto,
                          imagen: doc.data().imagen,
                          fecha: doc.data().fecha+" "+doc.data().hora,
                      })
                  });
                  }
                 
            })
      },
     
      cargarDatos(key,nombreProveedor,persona,participantes,lugar,fecha,hora,observacion,relator,usuarioRegistro){
        var user=localStorage.usuario;
        console.log(usuarioRegistro,user)
        if(user==usuarioRegistro){
          this.key=key;
          this.proveedor=nombreProveedor;
          this.relator=relator;
          this.participantes=participantes;
          this.lugar=lugar;
          var f1=fecha.split("-")
          var año=f1[2]
          var mes=f1[1]
          var dia=f1[0]
          this.fecha=año+"-"+mes+"-"+dia;
          this.persona=persona;
          this.hora=hora;
          this.observacion=observacion;
          this.$bvModal.show("modal-1")
        }else{
  
          console.log("Error");
                            Swal.fire({
                            title: "Error",
                            text: "Sólo el usuario que registró el evento puede editarlo",
                            icon: "error",
                          })
        }
      },
      editar() {
        console.log(this.persona)
          if(confirm("Deseas editar la capacitación?")){
              var capacitacionEditada = new Object();
                                  capacitacionEditada.key=this.key;
                  capacitacionEditada.nombreProveedor=this.proveedor;
                  capacitacionEditada.relator=this.relator;
                  capacitacionEditada.participantes=this.participantes;
                  capacitacionEditada.lugar=this.lugar;
                  //console.log(this.fecha)
                 // capacitacionEditada.fecha=this.fecha;
                  capacitacionEditada.semanaFecha=this.fecha;
                  capacitacionEditada.hora=this.hora;
                  capacitacionEditada.persona=this.persona;
                  capacitacionEditada.observacion=this.observacion;
                  var fecha12=this.fecha.split("-");
                  var año=fecha12[0]
                  var mes=fecha12[1]
                  var dia=fecha12[2]
                  var fechaF1=año+mes+dia;
                  capacitacionEditada.frcapacitacion=fechaF1;
                  capacitacionEditada.fecha=dia+"-"+mes+"-"+año
                                  this.capacitacionEditada.push(capacitacionEditada)
                  this.editar2();
                 // this.editarTest();
          }
                 
      },
      editar2(){
         var data=this.capacitacionEditada;
                  data.forEach((doc) => {
                    console.log(doc)
                      db.collection('capacitaciones').doc(doc.key)
                      .update(doc).then(() => {
                          console.log("LISTO");
                            Swal.fire({
                            title: "Listo",
                            text: "Evento editado",
                            icon: "success",
                          })
                          //this.capacitacionEditada=[];
                          this.$bvModal.hide("modal-1")
                          //this.camposMail()
                      }).catch((error) => {
                          console.log(error);
                      });
                  });      
      },
  
       camposMail(){
            console.log(this.capacitacionEditada[0].persona+" ha modificado el evento registrado de "+this.capacitacionEditada[0].nombreProveedor)
         /*
              console.log(
              this.capacitacion.persona,
              this.capacitacion.tipo,
              this.capacitacion.lugar,
              this.capacitacion.observacion,
              this.capacitacion.fecha,
              this.capacitacion.hora        
              )    */
      },
  
  
  
  
      editarTest(){
        console.log(this.capacitacionEditada)
         var data=this.capacitacionEditada;
                  data.forEach((dato) => {
                    console.log(dato)
                    
                        db.collection('capacitaciones').doc(dato.key).set({
                              relator: this.capacitacionEditada[0].relator,
                              fecha: this.capacitacionEditada[0].fecha,
                              hora: this.capacitacionEditada[0].hora,
                              lugar: this.capacitacionEditada[0].lugar,
                              nombreProveedor: this.capacitacionEditada[0].proveedor,
                              participantes: this.capacitacionEditada[0].participantes,
                              frcapacitacion: this.capacitacionEditada[0].frcapacitacion,
                              persona: this.capacitacionEditada[0].persona,
                              observacion: this.capacitacionEditada[0].observacion,
  
                          },
                           { merge: true });    
                  });
  
      },
      validarUsuario(){
              if(this.usuario==undefined || this.usuario=="X"){
                  this.$router.push('/')
              }
          },
  
      leer(){
                  var rut = document.getElementById("rut").value;
                  var rutx=rut.replace("-","");
                  var largo=rutx.length
                  //console.log(largo,largo-1)
                  var rut2=rutx.slice(0,largo-1)
                  var rut3=rutx.slice(largo-1)
                  //console.log(rut2+"-"+rut3)
                  document.getElementById("rut").value=(rut2+"-"+rut3)
                  
          },
        imprimirEtiqueta(numero,cliente,rut,telefono,envio,direccion,fechaVenta){
           

            this.datosEtiqueta=[]
            var x=new Object()
            x.numero=numero,
            x.cliente=cliente,
            x.rut=rut,
            x.telefono=telefono,
            x.envio=envio,
            x.direccion=direccion
            x.fechaVenta=fechaVenta
            this.datosEtiqueta.push(x)
            setTimeout(() => {
                        window.print()
                      }, 1500)

        }
      
    },
  };
  </script>
  
  <style>
  .btn-primary {
    margin-right: 12px;
  }
  </style>